/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.error {
  color: red;
  font-size: 13px;
}

.tablex {
  border-collapse: collapse;
  border: 1px solid #ddd; 
  width: 100%;
}

.tablex td, .tablex th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}

.tablex td {
  width: 50%;
}

.tablex td, .tablex th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}

.tablex thead {
  font-weight: bold; 
}

.table {
  border-collapse: collapse;
  border: 1px solid #ddd; 
  width: 100%;
}

.table td, .table th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}
.table td {
  width: 50%;
}


.table td, .table th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}
.table td {
  width: 50%;
}
.table thead {
  font-weight: bold; 
}

.table tr:hover {
  cursor: pointer;
}